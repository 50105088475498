@import "../yaml-var-typography",
		"../mixins/yaml-mixins-core";

@media all {

	/*!
	* @section global typography settings
	*
	* vertical rhythm settings (based on em-unit)
	* -------------------------------------------
	* basefont-size:  #{$ym-base-font-size}px (#{$ym-base-font-size/$ym-default-font-size*100}%)
	* line-height  :  #{$ym-base-line-height}px (factor: #{$ym-base-line-height / $ym-base-font-size}) */

	/* Alle Schriftgrößen auf Standardgröße (16 Pixel) zurücksetzen */

	html * { font-size:100%; }

	/**
	* Schriftgröße von monospaced Elemente in allen Browsern auf 16 Pixel setzen
	*
	* @see: http://webkit.org/blog/67/strange-medium/
	*/

	textarea,
	pre,
	code,
	kbd,
	samp,
	var,
	tt {
		font-family: $ym-font-monospace;
	}

	/*! font-size: #{$ym-base-font-size}px; */
	body {
		font-family: $ym-font-sans;
		font-size: $ym-base-font-size / $ym-default-font-size * 100%;
		font-weight: 300;
		color: $ym-text-color;
	}

	/*--- Überschriften ------------------------------------------------------------------------*/

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $ym-font-sans;
		font-weight: 300;
		color: $ym-headings-color;
		margin: 0;
	}

	h1 {
		/*! font-size: #{$ym-base-font-size * $ym-h1-font-scale}px; */
		font-size: 200%;
		line-height: 2 * $line-height / $ym-h1-font-scale;
		margin: 0 0 1em 0;

		&:first-child {
			margin-top: 1.5em;
		}

		&.home-title {
			margin-top: 0;
		}
	}

	h2 {
		/*! font-size: #{$ym-base-font-size * $ym-h2-font-scale}px; */
		font-size: 160%;
		line-height: 2 * $line-height / $ym-h2-font-scale;
		margin: 0 0 0.5em 0;

		p + & {
			margin-top: 2em;

		}
		
	}

	h3 {
		/*! font-size: #{$ym-base-font-size * $ym-h3-font-scale}px; */
		font-size: 130%;
		line-height: 1 * $line-height / $ym-h3-font-scale;
		margin: 0 0 $line-height / $ym-h3-font-scale*1em 0;
	}

	h4 {
		/*! font-size: #{$ym-base-font-size * $ym-h4-font-scale}px; */
		font-size: $ym-h4-font-scale * 100%;
		line-height: 1 * $line-height / $ym-h4-font-scale;
		margin: 0 0 $line-height / $ym-h4-font-scale*1em 0;
	}

	h5 {
		/*! font-size: #{$ym-base-font-size * $ym-h5-font-scale}px; */
		font-size: $ym-h5-font-scale * 100%;
		line-height: 1 * $line-height / $ym-h5-font-scale;
		margin: 0 0 $line-height / $ym-h5-font-scale*1em 0;
	}

	h6 {
		/*! font-size: #{$ym-base-font-size * $ym-h6-font-scale}px; */
		font-size: $ym-h6-font-scale * 100%;
		font-weight: bold;
		line-height: 1 * $line-height / $ym-h6-font-scale;
		margin: 0 0 $line-height / $ym-h6-font-scale*1em 0;
	}

	/* --- Listen  -------------------------------------------------------------------------------- */

	ul,
	ol,
	dl {
		font-size: $ym-text-font-scale * 100%;
		line-height: 1 * $line-height / $ym-text-font-scale;
		margin: 0 0 $line-height / $ym-text-font-scale*1em 0;
	}

	ul {
		list-style-type:disc;
	}

	ol {
		list-style-type:decimal;
	}

	ul ul {
		list-style-type:circle;
		margin-top:0;
	}

	ol ol {
		list-style-type:lower-latin;
		margin-top:0;
	}

	ol ul {
		list-style-type:circle;
		margin-top:0;
	}

	li {
		font-size: $ym-text-font-scale * 100%;
		line-height: 1 * $line-height / $ym-text-font-scale;
		margin-left: 1.2em;
	}

	dt {
		font-weight:bold;
	}

	dd {
		margin: 0 0 $line-height*1em 0.8em;
	}

	/* --- Allgemeine Textauszeichnung ------------------------------------------ */

	p {
		font-size: $ym-text-font-scale * 100%;
		line-height: 1 * $line-height / $ym-text-font-scale;
		margin: 0 0 $line-height / $ym-text-font-scale*1em 0;
	}

	blockquote,
	cite,
	q {
		font-style: normal;
	}



	blockquote {
		margin: 2em 1.5em 1em 1.5em;
		text-align: center;
		font-weight: 400;
	}

	strong,
	b {
		font-weight: bold;
	}

	em,
	i {
		font-style: italic;
	}

	big {
		/*! font-size: #{$ym-base-font-size * $ym-text-big-font-scale}px; */
		font-size: $ym-text-big-font-scale * 100%;
		line-height: 1 * $line-height / $ym-text-big-font-scale;
	}

	small {
		/*! font-size: #{$ym-base-font-size * $ym-text-small-font-scale}px; */
		font-size: $ym-text-small-font-scale * 100%;
		line-height: 1 * $line-height / $ym-text-small-font-scale;
	}

	
	/*--- Links ----------------------------------------------------------------------------------------- */

	a {
		
		text-decoration: underline;
		color: $ym-text-color;
	}

	a:active { outline: none; }

	/* Maximaler Kontrast für Tab Focus - Ändern Sie diese Regel mit Bedacht */
	a:hover,
	a:focus {
		background-color: $ym-link-focus-bg-color;
		color: $ym-link-focus-text-color;
		text-decoration: none;
	}
	
	// Spezielle Linkklassen
	
	.footer-link {
		text-transform: uppercase;
		font-size: 0.9em;
		text-decoration: none;
		display: inline-block;
		margin-right: 10px;
		
		&:hover {
			text-decoration: none;
		}
	}


	/* --- images ------------------ */

	img,
	figure {
		margin: 0;
	}

	.flexible {
		margin-bottom: $line-height*1em;
		max-width: 100%;
		height: auto;
	}

	/* IE6 workaround - 2% space for possible borders */
	* html .flexible {
		width: 98%;
	}

	.bordered {
		margin-bottom: $line-height*1em;
		border: 2px #eee solid;
		border: 2px rgba(255,255,255,1) solid;
		@include ym-box-shadow(0,0,3px,rgba(0,0,0,.25));
	}

	/**
	* ----------------------------------------------------------------------- #
	*
	* Generic Content Classes
	*
	* Standardklassen zur Positionierung und Hervorhebung
	*
	* @section content-generic-classes
	*/

	.small {
		font-size: 0.85em;
	}

	.big {
		font-size: 1.2em;
	}

	.float-left {
		float: left;
		display: inline;
		margin: 0 1em $line-height*1em 0;
	}

	.float-right {
		float: right;
		display: inline;
		margin: 0 0 $line-height*1em 1em;
	}

	.center {
		display: block;
		text-align: center;
		margin: 0 auto $line-height*1em auto;
	}

	.autor {
		text-align: center;
		font-size: 0.9em;
	}

	.zitat-page {
		margin:4em 3em 1em 3em;
	}

	.logo-featured {
		margin-top: 65px;
	}

	/**
	* ------------------------------------------------------------------------------------------------- #
	*
	* Tabellen
	*
	* Generische Klassen für die Tabellenbreite und Gestaltungsvorschriften für Tabellen
	*
	* @section content-tables
	*/

	table {
		width: 100%;
		border-collapse: collapse;
		color: $ym-text-color;
		border-top: 1px #ccc solid;
		border-bottom: 1px #ccc solid;
		margin: 0 0 ($ym-base-line-height - 2 ) / $ym-base-line-height * $line-height * 1em 0;

		&.narrow {
			margin: 0 0 ($ym-base-line-height - 1 ) / $ym-base-line-height * $line-height * 1em 0;

			th,
			td {
				padding: 0 0.5em;
				line-height: ($ym-base-line-height - 1 ) / $ym-base-line-height * $line-height;
			}
		}

		&.fixed {
			table-layout: fixed;
		}

		&.bordertable {
			border: 1px #ccc solid;

			thead th {
				background: #e0e0e0;
				border-right: 1px #ccc solid;
				border-bottom: 1px #ccc solid;
			}

			tbody {
				th[scope="row"] {
					background: #f0f0f0;
				}
				th {
					border-right: 1px solid #ccc;
				}
				td {
					border-right: 1px solid #ccc;
				}
			}
		}
	}

	caption {
		font-variant:small-caps;
	}

	th,
	td {
		line-height: $line-height * 1em;
		vertical-align: top;
		padding: ($ym-base-line-height - 1 ) / $ym-base-line-height * $line-height / 2 * 1em 0.5em;
	}

	th *:first-child,
	td *:first-child {
		margin-top: 0;
	}

	th.nowrap,
	td.nowrap {
		white-space: nowrap;
	}

	thead th {
		text-align: left;
		color: #000;
		border-bottom: 2px #000 solid;
	}

	tbody {
		th {
			text-align: left;
			border-top: 1px solid #ccc;
		}

		td {
			text-align: left;
			border-top: 1px solid #ccc;
		}

		/* highlight row on mouse over */
		tr:hover th,
		tr:hover td {
			background:#f8f8f8;
		}
	}
}