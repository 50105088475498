@import "../yaml-var-globals",
		"../mixins/yaml-mixins-core";

@media all {
	@import "base-modules/normalization",
			"base-modules/float-handling",
			"base-modules/columns",
			"base-modules/grids-core",
			"base-modules/forms-core",
			"base-modules/accessibility";
}

@media print {
	@import "base-modules/print-core";
}