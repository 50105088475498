@import "../_yaml-var-globals",
		"../mixins/yaml-mixins-core";

@media screen {

	
	body {
		overflow-y: scroll;/* force vertical scrollbar */
		background-color: #f0ecf3;
	}

	/* Layout Module Configuration & fallback for unsupported media queries */
	.ym-wrapper {
		min-width: 760px;
		width:80%;
		max-width:1300px;
		margin: 0 auto;
	}

	.ym-wbox { background-color: #fff; }

	
	.title-img {
			height: 705px;
			margin-top:130px
	}
	
	.title-home {
			background: url('img/title-home.jpg') no-repeat top center;
			background-size: cover;
	}
	
	.title-osteopathie {
			background: url('img/osteopathie.jpg') no-repeat top center;
			background-size: cover;
	}

	.title-inpp {
		background: url('img/lampe.jpg') no-repeat top center;
		background-size: cover;
	}

	.title-about {
		background: url('img/mirjam-auerbach.jpg') no-repeat top center;
		background-size: cover;
	}

	.title-praxis {
		background: url('img/praxis.jpg') no-repeat top center;
		background-size: cover;
	}

	.title-kontakt {
	//	background: url('img/hocker.jpg') no-repeat top center;
		background: url('img/hello.jpg') no-repeat top center;
		background-size: cover;
	}

	.title-impressum {
		background: url('img/hand-fuss.jpg') no-repeat top center;
		background-size: cover;
	}
	
	.branding {
		float: left;
		margin: 54px 20px 0 20px;
	}
	
	.branding {
		a {
			display: block;
			padding:0;
			color:#4e4e4e;
			text-transform: uppercase;
			text-decoration: none;
			letter-spacing: 1px;
			font-size: 18px;
			
			&:hover {
				background: none;
			}
		}
	}
	
	
	header {		

		nav {
			float: right;
			margin-right:20px;
			margin-top: 36px;
			
		}
	
	}
	
	header.smaller {
		.branding {
			margin-top: 30px;
		}
		nav {
			margin-top: 14px;
		}
	}



	.main {
		padding: 50px 150px;
	}

	footer {
		margin: 0;
		padding: 1.5em;
		background-color: #e2dfdb;

		p {
			margin: 0;
			font-size: 0.9em;
		}
		
		.left {
			float: left;
			margin-right:20px;
		}
		
		.right {
			float: right;
			margin-left: 20px;
		}
	}

	/* Column-Set Configuration: 1-3 (sidebar right) */
	.ym-column {
		display:block;
		overflow:hidden;
		padding-right: 340px;
		width:auto;
	}

	/* content - column */
	.ym-col1 {
		float:left;
		width: 100%;
	}

	/* sidebar - column */
	.ym-col3 {
		position:relative;
		float:left;
		width: 340px;
		margin-left:0;
		margin-right:-340px;
	}

	.ym-col1 .ym-cbox { padding: 0 0.75em 0 1.5em; }
	.ym-col3 .ym-cbox { padding: 0 20px 0 12px; }

	.ym-gbox { padding: 0 1.5em 0 0; }

}

// 2-spaltiges Grid

.ym-g50.ym-gl {
	.ym-gbox {
		padding-right:2em;
		padding-left: 0;
	}
}

.ym-g50.ym-gr {
	.ym-gbox {
		padding-left:2em;
		padding-right: 0;
	}
}

/* reset fallback values in modern browsers */
@media screen and ( min-width: 0px ) {
	.ym-wrapper {
		min-width: 0px;
	}
}

@media screen and ( max-width: 1300px) {
	.title-img {
				height: 500px;
		}
		
}

@media screen and ( max-width: 1100px) {
	.ym-wrapper {
		width: 90%;
	}
		
	.main {
    	padding: 50px 100px;
	}
		
	// 2-spaltiges Grid

	.ym-g50.ym-gl {
		.ym-gbox {
			padding-right:1.5em;
		}
	}
	
	.ym-g50.ym-gr {
		.ym-gbox {
			padding-left:1.5em;
		}
	}

}

@media screen and ( max-width: 1020px) {
	

	
	header {
		.branding {
			a {
				padding:0;
			}
	}		

		nav {
			float: right;
		    margin-top: -37px;
		    margin-right: 0;
		    width: 100%;
		    margin-left: 100px;
		    text-align: right;
		}
	}
	
	header.smaller {
		.branding {
			margin-top: 30px;
		}
		nav {
			margin-top: -39px;
		}
	}


	.title-img {
				height: 400px;
		}
		

	
}

@media screen and ( max-width: 768px ) {

	/* Linearisation for Grid- and Column-Module, based on CSS class .linearize-level-1 */
	.linearize-level-1 {
		@include ym-linearize-grids-and-columns();
	}
	
	.main {
    padding: 50px 50px;
}
	
	header {
		text-align: center;		
	}
	
	.logo {
		float: none;
		margin: 0 auto;
	}

	nav {
		float: none;
		margin: 30px auto 0px auto;
	}

	}
	
@media screen and ( max-width: 660px) {

	.ym-wrapper {
		width: 100%;
	}
	
	
	header.smaller {
		.branding {
			margin-top: 18px;
		}
		nav {
			margin-top: -39px;
		}
	}
	
	
	.title-img {
				height: 300px;
		}
}

@media screen and ( max-width: 600px) {

	
}

@media screen and ( max-width: 480px) {

	.ym-wbox {
		padding: 0;
	}



	footer,
	.main {
		padding-left: 10px;
		padding-right: 10px;
	}

	/* Linearisation for Grid- and Column-Module, based on CSS class .linearize-level-2 */
	.linearize-level-2 {
		@include ym-linearize-grids-and-columns();
	}

	
	.title-img {
				height: 250px;
		}

}