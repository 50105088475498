@if $ym-base-columns == true {
	/**
	* @section Column Module
	*
	* default column config:
	* |-------------------------------|
	* | col1    | col3      | col2    |
	* | 20%     | flexible  | 20%     |
	* |-------------------------------|
	*/

	.ym-column {
		@include ym-contain(dt);
	}

	.ym-col1 { float:left; width:$ym-column-width; }
	.ym-col2 { float:right; width:$ym-column-width; }
	.ym-col3 { width:auto; margin:0 $ym-column-width; }

	.ym-cbox { padding: 0 $ym-cbox-padding }
	.ym-cbox-left { padding: 0 $ym-cbox-padding 0 0 }
	.ym-cbox-right { padding: 0 0 0 $ym-cbox-padding }

	/* (en) IE-Clearing: Only used in Internet Explorer, switched on in iehacks.css */
	/* (de) IE-Clearing: Benötigt nur der Internet Explorer und über iehacks.css zugeschaltet */
	.ym-ie-clearing { display:none; }
}