

/* =Scss Variables
-------------------------------------------------------------- */
// fonts


// colours
$white:rgb(255,255,255);
$color:rgb(60,181,249);

$dark-grey:rgb(80,80,80);
$text-grey:rgb(100,100,100);





/* =Global
-------------------------------------------------------------- */
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}






/* =Template
-------------------------------------------------------------- */

.container {
    width:80%;
    max-width:1300px;
    height:130px;
    margin:0 auto;
    padding:0;
    background-color: #fff;
    
    .smaller & {
	    height: 80px;
	    -webkit-transition:height 0.3s;
		-moz-transition:height 0.3s;
		-ms-transition:height 0.3s;
		-o-transition:height 0.3s;
		transition:height 0.3s;
    }
}









/* =Header
-------------------------------------------------------------- */
header {
	width:100%;
	position:fixed;
	top:0;
	left:0;
	z-index:999;
	-webkit-transition:height 0.3s;
	-moz-transition:height 0.3s;
	-ms-transition:height 0.3s;
	-o-transition:height 0.3s;
	transition:height 0.3s;
		nav {
			a {
				line-height: 150px;
				margin-left:20px;
				color:lighten($color, 20%);
				font-weight:700;
				font-size:18px;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				-ms-transition: all 0.3s;
				-o-transition: all 0.3s;
				transition: all 0.3s;
				&:hover {
					color:$white;
				}
			}
	}
	&.smaller {
		height:75px;

		nav {
			a {
				line-height:75px;
			}
		}
	}
}





/* =Extras
-------------------------------------------------------------- */
.clearfix:after {
	visibility: hidden;
	display: block;
	content: "";
	clear: both;
	height: 0;
}





/* =Media Queries
-------------------------------------------------------------- */

@media screen and ( max-width: 1100px) {
	.container {
	    width:90%;
	}

	
}




@media all and (max-width: 660px) {
	/* =Header
	-------------------------------------------------------------- */
	.container {
		width:100%;
	}
	
	header {
		h1#logo {
			display:block;
			float:none;
			margin:0 auto;
			height:100px;
			line-height: 100px;
			text-align: center;
		}
		nav {
			display:block;
			float:right;
			width:100%;
			height:50px;
			margin:-36px 0 0 100px;
			a {
				line-height: 50px;
				margin:0 10px;
			}
		}
		&.smaller {
			height:75px;
			h1#logo {
				height:40px;
				line-height:40px;
				font-size:30px;
			}
			nav {
				height:35px;
				a {
					line-height:35px;
				}
			}
		}
		&.smaller .container {
			height: 62px;
		}
	}
}

@media all and (max-width: 600px) {
	
	#info-bar {
		a {
			display: block;
		}
		span.all-tutorials,
		span.back-to-tutorial {
			width:100%;
		}
		span.all-tutorials,
		span.back-to-tutorial {
			float:none;
			text-align:center;
		}
		span.all-tutorials {
			border-bottom:solid 1px darken($color, 15%);
		}
	}
}