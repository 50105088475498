@if $ym-base-print == true {

	@if $ym-base-grids == true {
	/**
	* @section print adjustments for core modules
	*
	* (en) float containment for grids. Uses display:table to avoid bugs in FF & IE
	* (de) Floats in Grids einschließen. Verwendet display:table, um Darstellungsprobleme im FF & IE zu vermeiden
	*
	* @bugfix
	* @since     3.0
	* @affected  FF2.0, FF3.0, IE7
	* @css-for   all browsers
	* @valid     yes
	*/
		.ym-grid > .ym-gl,
		.ym-grid > .ym-gr {
			overflow:visible;
			display:table;
			table-layout: fixed
		}
	}

	/* (en) make .ym-print class visible */
	/* (de) .ym-print-Klasse sichtbar schalten */
	.ym-print {
		position:static;
		left:0;
	}

	/* (en) generic class to hide elements for print */
	/* (de) Allgemeine CSS Klasse, um beliebige Elemente in der Druckausgabe auszublenden */
	.ym-noprint {
		display:none !important;
	}
}