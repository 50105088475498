@charset "UTF-8";
html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

@media all {
  /**
	 *  @section Normalisation Module
	 */
  /* (en) Global reset of paddings and margins for all HTML elements */
  /* (de) Globales Zurücksetzen der Innen- und Außenabstände für alle HTML-Elemente */
  * {
    margin: 0;
    padding: 0;
  }
  /* (en) Correction: margin/padding reset caused too small select boxes. */
  /* (de) Korrektur: Das Zurücksetzen der Abstände verursacht zu kleine Selectboxen. */
  option {
    padding-left: 0.4em;
  }
  select {
    padding: 1px;
  }
  /*
	* (en) Global fix of the Italics bugs in IE 5.x and IE 6
	* (de) Globale Korrektur des Italics Bugs des IE 5.x und IE 6
	*
	* @bugfix
	* @affected   IE 5.x/Win, IE6
	* @css-for    IE 5.x/Win, IE6
	* @valid      yes
	*/
  * html body * {
    overflow: visible;
  }
  /*
	* (en) Fix for rounding errors when scaling font sizes in older versions of Opera browser
	*      Standard values for colors and text alignment
	*
	* (de) Beseitigung von Rundungsfehler beim Skalieren von Schriftgrößen in älteren Opera Versionen
	*      Vorgabe der Standardfarben und Textausrichtung
	*/
  body {
    font-size: 100%;
    background: #fff;
    color: #000;
    text-align: left;
  }
  /* (en) avoid visible outlines on DIV and h[x] elements in Webkit browsers */
  /* (de) Vermeidung sichtbarer Outline-Rahmen in Webkit-Browsern */
  div:target,
  h1:target,
  h2:target,
  h3:target,
  h4:target,
  h5:target,
  h6:target {
    outline: 0 none;
  }
  /* (en) HTML5 - adjusting visual formatting model to block level */
  /* (de) HTML5 - Elements werden als Blockelemente definiert */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  nav,
  section,
  summary {
    display: block;
  }
  /* (en) HTML5 - default media element styles */
  /* (de) HTML5 - Standard Eigenschaften für Media-Elemente */
  audio,
  canvas,
  video {
    display: inline-block;
  }
  /* (en) HTML5 - don't show <audio> element if there aren't controls */
  /* (de) HTML5 - <audio> ohne Kontrollelemente sollten nicht angezeigt werden */
  audio:not([controls]) {
    display: none;
  }
  /* (en) HTML5 - add missing styling in IE & old FF for hidden attribute */
  /* (de) HTML5 - Eigenschaften für das hidden-Attribut in älteren IEs und FF nachrüsten */
  [hidden] {
    display: none;
  }
  /* (en) Prevent iOS text size adjust after orientation change, without disabling user zoom. */
  /* (de) Verdindert die automatische Textanpassung bei Orientierungswechsel, ohne Zoom zu blockieren */
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  /* (en) set correct box-modell in IE8/9 plus remove padding */
  /* (de) Setze das richtige Box-Modell im IE8/9 und entferne unnötiges Padding */
  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }
  /* (en) force consistant appearance of input[type="search"] elements in all browser */
  /* (de) Einheitliches Erscheinungsbild für input[type="search"] Elemente erzwingen */
  input[type="search"] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  /* (en) Correct overflow displayed oddly in IE 9 */
  /* (de) Korrigiert fehlerhafte overflow Voreinstellung des IE 9 */
  svg:not(:root) {
    overflow: hidden;
  }
  /* (en) Address margin not present in IE 8/9 and Safari 5 */
  /* (en) Ergänzt fehlenden Margin in IE 8/9 und Safari 5 */
  figure {
    margin: 0;
  }
  /* (en) Clear borders for <fieldset> and <img> elements */
  /* (de) Rahmen für <fieldset> und <img> Elemente löschen */
  fieldset,
  img {
    border: 0 solid;
  }
  /* (en) new standard values for lists, blockquote, cite and tables */
  /* (de) Neue Standardwerte für Listen, Zitate und Tabellen */
  ul,
  ol,
  dl {
    margin: 0 0 1em 1em;
  }
  li {
    line-height: 1.5em;
    margin-left: 0.8em;
  }
  dt {
    font-weight: bold;
  }
  dd {
    margin: 0 0 1em 0.8em;
  }
  blockquote {
    margin: 0 0 1em 0.8em;
  }
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /**
	* @section Float Handling Module
	*/
  /* (en) clearfix method for clearing floats */
  /* (de) Clearfix-Methode zum Clearen der Float-Umgebungen */
  .ym-clearfix:before {
    content: "";
    display: table;
  }
  .ym-clearfix:after {
    clear: both;
    content: ".";
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
  }
  /* (en) alternative solutions to contain floats */
  /* (de) Alternative Methoden zum Einschließen von Float-Umgebungen */
  .ym-contain-dt {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .ym-contain-oh {
    display: block;
    overflow: hidden;
    width: 100%;
  }
  .ym-contain-fl {
    float: left;
    width: 100%;
  }
  /**
	* @section Column Module
	*
	* default column config:
	* |-------------------------------|
	* | col1    | col3      | col2    |
	* | 20%     | flexible  | 20%     |
	* |-------------------------------|
	*/
  .ym-column {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .ym-col1 {
    float: left;
    width: 20%;
  }
  .ym-col2 {
    float: right;
    width: 20%;
  }
  .ym-col3 {
    width: auto;
    margin: 0 20%;
  }
  .ym-cbox {
    padding: 0 10px;
  }
  .ym-cbox-left {
    padding: 0 10px 0 0;
  }
  .ym-cbox-right {
    padding: 0 0 0 10px;
  }
  /* (en) IE-Clearing: Only used in Internet Explorer, switched on in iehacks.css */
  /* (de) IE-Clearing: Benötigt nur der Internet Explorer und über iehacks.css zugeschaltet */
  .ym-ie-clearing {
    display: none;
  }
  /**
	* @section Grid Module
	*/
  .ym-grid {
    display: table;
    table-layout: fixed;
    width: 100%;
    list-style-type: none;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .ym-gl {
    float: left;
    margin: 0;
  }
  .ym-gr {
    float: right;
    margin: 0 0 0 -5px;
  }
  .ym-g20 {
    width: 20%;
  }
  .ym-g40 {
    width: 40%;
  }
  .ym-g60 {
    width: 60%;
  }
  .ym-g80 {
    width: 80%;
  }
  .ym-g25 {
    width: 25%;
  }
  .ym-g33 {
    width: 33.333%;
  }
  .ym-g50 {
    width: 50%;
  }
  .ym-g66 {
    width: 66.666%;
  }
  .ym-g75 {
    width: 75%;
  }
  .ym-g38 {
    width: 38.2%;
  }
  .ym-g62 {
    width: 61.8%;
  }
  .ym-gbox {
    padding: 0 10px;
  }
  .ym-gbox-left {
    padding: 0 10px 0 0;
  }
  .ym-gbox-right {
    padding: 0 0 0 10px;
  }
  .ym-equalize {
    overflow: hidden;
  }
  .ym-equalize > [class*="ym-g"] {
    display: table-cell;
    float: none;
    margin: 0;
    vertical-align: top;
  }
  .ym-equalize > [class*="ym-g"] > [class*="ym-gbox"] {
    padding-bottom: 10000px;
    margin-bottom: -10000px;
  }
  /**
	* @section Form Module
	*/
  /** Vertical-Forms - technical base (standard)
	*
	* |-------------------------------|
	* | form                          |
	* |-------------------------------|
	* |   label                       |
	* |   input / select / textarea   |
	* |-------------------------------|
	* | /form                         |
	* |-------------------------------|
	*
	* (en) Styling of forms where both label and input/select/textarea are styled with display:block;
	* (de) Formulargestaltung, bei der sowohl label als auch input/select/textarea mit display:block; gestaltet werden
	*/
  .ym-form,
  .ym-form fieldset {
    overflow: hidden;
  }
  .ym-form div {
    position: relative;
  }
  .ym-form label,
  .ym-form .ym-label,
  .ym-form .ym-message {
    position: relative;
    line-height: 1.5;
    display: block;
  }
  .ym-form .ym-message {
    clear: both;
  }
  .ym-form .ym-fbox-check label {
    display: inline;
  }
  .ym-form input,
  .ym-form textarea {
    cursor: text;
  }
  .ym-form .ym-fbox-check input,
  .ym-form input[type="image"],
  .ym-form input[type="radio"],
  .ym-form input[type="checkbox"],
  .ym-form select,
  .ym-form label {
    cursor: pointer;
  }
  .ym-form textarea {
    overflow: auto;
  }
  .ym-form input.hidden,
  .ym-form input[type=hidden] {
    display: none !important;
  }
  .ym-form .ym-fbox:before,
  .ym-form .ym-fbox-text:before,
  .ym-form .ym-fbox-select:before,
  .ym-form .ym-fbox-check:before,
  .ym-form .ym-fbox-button:before {
    content: "";
    display: table;
  }
  .ym-form .ym-fbox:after,
  .ym-form .ym-fbox-text:after,
  .ym-form .ym-fbox-select:after,
  .ym-form .ym-fbox-check:after,
  .ym-form .ym-fbox-button:after {
    clear: both;
    content: ".";
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
  }
  .ym-form .ym-fbox-check input:focus,
  .ym-form .ym-fbox-check input:hover,
  .ym-form .ym-fbox-check input:active,
  .ym-form input[type="radio"]:focus,
  .ym-form input[type="radio"]:hover,
  .ym-form input[type="radio"]:active,
  .ym-form input[type="checkbox"]:focus,
  .ym-form input[type="checkbox"]:hover,
  .ym-form input[type="checkbox"]:active {
    border: 0 none;
  }
  .ym-form input,
  .ym-form textarea,
  .ym-form select {
    display: block;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 70%;
  }
  .ym-form .ym-fbox-check input,
  .ym-form input[type="radio"],
  .ym-form input[type="checkbox"] {
    display: inline;
    margin-left: 0;
    margin-right: 0.5ex;
    width: auto;
    height: auto;
  }
  .ym-form input[type="image"] {
    border: 0;
    display: inline;
    height: auto;
    margin: 0;
    padding: 0;
    width: auto;
  }
  .ym-form label,
  .ym-form .ym-label {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .ym-form .ym-fbox-button input {
    display: inline;
    overflow: visible;
    width: auto;
  }
  .ym-form .ym-inline {
    display: inline-block;
    float: none;
    margin-right: 0;
    width: auto;
    vertical-align: baseline;
  }
  /* default form wrapper width */
  .ym-fbox-wrap {
    display: table;
    table-layout: fixed;
    width: 70%;
  }
  .ym-fbox-wrap input,
  .ym-fbox-wrap textarea,
  .ym-fbox-wrap select {
    width: 100%;
  }
  .ym-fbox-wrap input[type="image"] {
    width: auto;
  }
  .ym-fbox-wrap input[type="radio"],
  .ym-fbox-wrap input[type="checkbox"] {
    display: inline;
    width: auto;
    margin-left: 0;
    margin-right: 0.5ex;
  }
  .ym-fbox-wrap label,
  .ym-fbox-wrap .ym-label {
    display: inline;
  }
  .ym-full input,
  .ym-full textarea,
  .ym-full select {
    width: 100%;
  }
  .ym-full .ym-fbox-wrap {
    width: 100%;
  }
  /**
	*  Columnar forms display - technical base (optional)
	*
	*  |-------------------------------------------|
	*  | form                                      |
	*  |-------------------------------------------|
	*  |                                           |
	*  |   label   |   input / select / textarea   |
	*  |                                           |
	*  |-------------------------------------------|
	*  | /form                                     |
	*  |-------------------------------------------|
	*
	*  (en) Styling of forms where label floats left of form-elements
	*  (de) Formulargestaltung, bei der die label-Elemente nach links fließen
	*/
  .ym-columnar input,
  .ym-columnar textarea,
  .ym-columnar select {
    float: left;
    margin-right: -3px;
  }
  .ym-columnar label,
  .ym-columnar .ym-label {
    display: inline;
    float: left;
    width: 30%;
    z-index: 1;
  }
  .ym-columnar .ym-fbox-check input,
  .ym-columnar .ym-message {
    margin-left: 30%;
  }
  .ym-columnar .ym-fbox-wrap {
    margin-left: 30%;
    margin-right: -3px;
  }
  .ym-columnar .ym-fbox-wrap .ym-message {
    margin-left: 0%;
  }
  .ym-columnar .ym-fbox-wrap label {
    float: none;
    width: auto;
    z-index: 1;
    margin-left: 0;
  }
  .ym-columnar .ym-fbox-wrap input {
    margin-left: 0;
    position: relative;
  }
  .ym-columnar .ym-fbox-check {
    position: relative;
  }
  .ym-columnar .ym-fbox-check label,
  .ym-columnar .ym-fbox-check .ym-label {
    padding-top: 0;
  }
  .ym-columnar .ym-fbox-check input {
    top: 3px;
  }
  .ym-columnar .ym-fbox-button input {
    float: none;
    margin-right: 1em;
  }
  .ym-fbox-wrap + .ym-fbox-wrap {
    margin-top: 0.5em;
  }
  /* global and local columnar settings for button alignment */
  .ym-columnar fieldset .ym-fbox-button,
  fieldset.ym-columnar .ym-fbox-button {
    padding-left: 30%;
  }
  /**
	* @section Accessibility Module
	*
	* (en) skip links and hidden content
	* (de) Skip-Links und versteckte Inhalte
	*/
  /* (en) classes for invisible elements in the base layout */
  /* (de) Klassen für unsichtbare Elemente im Basislayout */
  .ym-skip,
  .ym-hideme,
  .ym-print {
    position: absolute;
    top: -32768px;
    left: -32768px;
  }
  /* (en) make skip links visible when using tab navigation */
  /* (de) Skip-Links für Tab-Navigation sichtbar schalten */
  .ym-skip:focus,
  .ym-skip:active {
    position: static;
    top: 0;
    left: 0;
  }
  /* skiplinks:technical setup */
  .ym-skiplinks {
    position: absolute;
    top: 0px;
    left: -32768px;
    z-index: 1000;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .ym-skiplinks .ym-skip:focus,
  .ym-skiplinks .ym-skip:active {
    left: 32768px;
    outline: 0 none;
    position: absolute;
    width: 100%;
  }
}

@media print {
  /**
	* @section print adjustments for core modules
	*
	* (en) float containment for grids. Uses display:table to avoid bugs in FF & IE
	* (de) Floats in Grids einschließen. Verwendet display:table, um Darstellungsprobleme im FF & IE zu vermeiden
	*
	* @bugfix
	* @since     3.0
	* @affected  FF2.0, FF3.0, IE7
	* @css-for   all browsers
	* @valid     yes
	*/
  .ym-grid > .ym-gl,
  .ym-grid > .ym-gr {
    overflow: visible;
    display: table;
    table-layout: fixed;
  }
  /* (en) make .ym-print class visible */
  /* (de) .ym-print-Klasse sichtbar schalten */
  .ym-print {
    position: static;
    left: 0;
  }
  /* (en) generic class to hide elements for print */
  /* (de) Allgemeine CSS Klasse, um beliebige Elemente in der Druckausgabe auszublenden */
  .ym-noprint {
    display: none !important;
  }
}

#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a,
#cssmenu #menu-button {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#cssmenu:after,
#cssmenu > ul:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

#cssmenu #menu-button {
  display: none;
}

#cssmenu {
  width: auto;
  line-height: 1;
  background: transparent;
}

#menu-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

#cssmenu ul {
  background-color: #fff;
}

#cssmenu > ul > li {
  float: left;
}

#cssmenu.align-center > ul {
  font-size: 0;
  text-align: center;
}

#cssmenu.align-center > ul > li {
  display: inline-block;
  float: none;
}

#cssmenu.align-center ul ul {
  text-align: left;
}

#cssmenu.align-right > ul > li {
  float: right;
}

#cssmenu.align-right ul ul {
  text-align: right;
}

#cssmenu > ul > li > a {
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  color: #4e4e4e;
  -webkit-transition: color .2s ease;
  -moz-transition: color .2s ease;
  -ms-transition: color .2s ease;
  -o-transition: color .2s ease;
  transition: color .2s ease;
}

#cssmenu > ul > li > a:hover {
  background: none;
}

#cssmenu > ul > li:hover > a {
  color: #aa85a3;
}

#cssmenu > ul > li.active > a {
  font-weight: 700;
}

#cssmenu > ul > li.has-sub > a {
  padding-right: 25px;
}

#cssmenu > ul > li.has-sub > a::after {
  position: absolute;
  top: 21px;
  right: 10px;
  width: 4px;
  height: 4px;
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
  content: "";
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: border-color 0.2s ease;
  -moz-transition: border-color 0.2s ease;
  -ms-transition: border-color 0.2s ease;
  -o-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease;
}

#cssmenu > ul > li.has-sub:hover > a::after {
  border-color: #009ae1;
}

#cssmenu ul ul {
  position: absolute;
  left: -9999px;
}

#cssmenu li:hover > ul {
  left: auto;
}

#cssmenu.align-right li:hover > ul {
  right: 0;
}

#cssmenu ul ul ul {
  margin-left: 100%;
  top: 0;
}

#cssmenu.align-right ul ul ul {
  margin-left: 0;
  margin-right: 100%;
}

#cssmenu ul ul li {
  height: 0;
  -webkit-transition: height .2s ease;
  -moz-transition: height .2s ease;
  -ms-transition: height .2s ease;
  -o-transition: height .2s ease;
  transition: height .2s ease;
}

#cssmenu ul li:hover > ul > li {
  height: 32px;
}

#cssmenu ul ul li a {
  padding: 10px 20px;
  width: 160px;
  font-size: 12px;
  background: #333333;
  text-decoration: none;
  color: #dddddd;
  -webkit-transition: color .2s ease;
  -moz-transition: color .2s ease;
  -ms-transition: color .2s ease;
  -o-transition: color .2s ease;
  transition: color .2s ease;
}

#cssmenu ul ul li:hover > a,
#cssmenu ul ul li a:hover {
  color: #ffffff;
}

#cssmenu ul ul li.has-sub > a::after {
  position: absolute;
  top: 13px;
  right: 10px;
  width: 4px;
  height: 4px;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  content: "";
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: border-color 0.2s ease;
  -moz-transition: border-color 0.2s ease;
  -ms-transition: border-color 0.2s ease;
  -o-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease;
}

#cssmenu.align-right ul ul li.has-sub > a::after {
  right: auto;
  left: 10px;
  border-bottom: 0;
  border-right: 0;
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
}

#cssmenu ul ul li.has-sub:hover > a::after {
  border-color: #ffffff;
}

@media all and (max-width: 1020px) {
  #cssmenu {
    width: 100%;
  }
  #cssmenu ul {
    width: 100%;
    display: none;
  }
  #cssmenu.align-center > ul,
  #cssmenu.align-right ul ul {
    text-align: left;
  }
  #cssmenu ul li,
  #cssmenu ul ul li,
  #cssmenu ul li:hover > ul > li {
    width: 100%;
    height: auto;
    border-top: 1px solid rgba(120, 120, 120, 0.15);
  }
  #cssmenu ul li {
    text-align: center;
  }
  #cssmenu ul li a,
  #cssmenu ul ul li a {
    width: 100%;
  }
  #cssmenu > ul > li,
  #cssmenu.align-center > ul > li,
  #cssmenu.align-right > ul > li {
    float: none;
    display: block;
  }
  #cssmenu ul ul li a {
    padding: 20px 20px 20px 30px;
    font-size: 12px;
    color: #000000;
    background: none;
  }
  #cssmenu ul ul li:hover > a,
  #cssmenu ul ul li a:hover {
    color: #000000;
  }
  #cssmenu ul ul ul li a {
    padding-left: 40px;
  }
  #cssmenu ul ul,
  #cssmenu ul ul ul {
    position: relative;
    left: 0;
    right: auto;
    width: 100%;
    margin: 0;
  }
  #cssmenu > ul > li.has-sub > a::after,
  #cssmenu ul ul li.has-sub > a::after {
    display: none;
  }
  #menu-line {
    display: none;
  }
  #cssmenu #menu-button {
    display: block;
    margin: -3px 20px 0 0;
    padding: 22px 43px 20px 20px;
    color: #000000;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
  }
  #cssmenu #menu-button::after {
    content: '';
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    width: 15px;
    height: 2px;
    background: #000000;
  }
  #cssmenu #menu-button::before {
    content: '';
    position: absolute;
    top: 25px;
    right: 20px;
    display: block;
    width: 15px;
    height: 7px;
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
  }
  #cssmenu .submenu-button {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    display: block;
    border-left: 1px solid rgba(120, 120, 120, 0.15);
    height: 52px;
    width: 52px;
    cursor: pointer;
  }
  #cssmenu .submenu-button::after {
    content: '';
    position: absolute;
    top: 21px;
    left: 26px;
    display: block;
    width: 1px;
    height: 11px;
    background: #000000;
    z-index: 99;
  }
  #cssmenu .submenu-button::before {
    content: '';
    position: absolute;
    left: 21px;
    top: 26px;
    display: block;
    width: 11px;
    height: 1px;
    background: #000000;
    z-index: 99;
  }
  #cssmenu .submenu-button.submenu-opened:after {
    display: none;
  }
}

@media all {
  /*!
	* @section global typography settings
	*
	* vertical rhythm settings (based on em-unit)
	* -------------------------------------------
	* basefont-size:  16px (100%)
	* line-height  :  26px (factor: 1.625) */
  /* Alle Schriftgrößen auf Standardgröße (16 Pixel) zurücksetzen */
  html * {
    font-size: 100%;
  }
  /**
	* Schriftgröße von monospaced Elemente in allen Browsern auf 16 Pixel setzen
	*
	* @see: http://webkit.org/blog/67/strange-medium/
	*/
  textarea,
  pre,
  code,
  kbd,
  samp,
  var,
  tt {
    font-family: Consolas, "Lucida Console", "Andale Mono", "Bitstream Vera Sans Mono", "Courier New", Courier;
  }
  /*! font-size: 16px; */
  body {
    font-family: "neuzeit-grotesk", Arial, Helvetica, sans-serif;
    font-size: 100%;
    font-weight: 300;
    color: #4e4e4e;
  }
  /*--- Überschriften ------------------------------------------------------------------------*/
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "neuzeit-grotesk", Arial, Helvetica, sans-serif;
    font-weight: 300;
    color: #161e21;
    margin: 0;
  }
  h1 {
    /*! font-size: 48px; */
    font-size: 200%;
    line-height: 1.08333;
    margin: 0 0 1em 0;
  }
  h1:first-child {
    margin-top: 1.5em;
  }
  h1.home-title {
    margin-top: 0;
  }
  h2 {
    /*! font-size: 25px; */
    font-size: 160%;
    line-height: 2.08;
    margin: 0 0 0.5em 0;
  }
  p + h2 {
    margin-top: 2em;
  }
  h3 {
    /*! font-size: 24px; */
    font-size: 130%;
    line-height: 1.08333;
    margin: 0 0 1.08333em 0;
  }
  h4 {
    /*! font-size: 21px; */
    font-size: 131.25%;
    line-height: 1.2381;
    margin: 0 0 1.2381em 0;
  }
  h5 {
    /*! font-size: 18px; */
    font-size: 112.5%;
    line-height: 1.44444;
    margin: 0 0 1.44444em 0;
  }
  h6 {
    /*! font-size: 14px; */
    font-size: 87.5%;
    font-weight: bold;
    line-height: 1.85714;
    margin: 0 0 1.85714em 0;
  }
  /* --- Listen  -------------------------------------------------------------------------------- */
  ul,
  ol,
  dl {
    font-size: 100%;
    line-height: 1.625;
    margin: 0 0 1.625em 0;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  ul ul {
    list-style-type: circle;
    margin-top: 0;
  }
  ol ol {
    list-style-type: lower-latin;
    margin-top: 0;
  }
  ol ul {
    list-style-type: circle;
    margin-top: 0;
  }
  li {
    font-size: 100%;
    line-height: 1.625;
    margin-left: 1.2em;
  }
  dt {
    font-weight: bold;
  }
  dd {
    margin: 0 0 1.625em 0.8em;
  }
  /* --- Allgemeine Textauszeichnung ------------------------------------------ */
  p {
    font-size: 100%;
    line-height: 1.625;
    margin: 0 0 1.625em 0;
  }
  blockquote,
  cite,
  q {
    font-style: normal;
  }
  blockquote {
    margin: 2em 1.5em 1em 1.5em;
    text-align: center;
    font-weight: 400;
  }
  strong,
  b {
    font-weight: bold;
  }
  em,
  i {
    font-style: italic;
  }
  big {
    /*! font-size: 16px; */
    font-size: 100%;
    line-height: 1.625;
  }
  small {
    /*! font-size: 12px; */
    font-size: 75%;
    line-height: 2.16667;
  }
  /*--- Links ----------------------------------------------------------------------------------------- */
  a {
    text-decoration: underline;
    color: #4e4e4e;
  }
  a:active {
    outline: none;
  }
  /* Maximaler Kontrast für Tab Focus - Ändern Sie diese Regel mit Bedacht */
  a:hover,
  a:focus {
    background-color: transparent;
    color: #aa85a3;
    text-decoration: none;
  }
  .footer-link {
    text-transform: uppercase;
    font-size: 0.9em;
    text-decoration: none;
    display: inline-block;
    margin-right: 10px;
  }
  .footer-link:hover {
    text-decoration: none;
  }
  /* --- images ------------------ */
  img,
  figure {
    margin: 0;
  }
  .flexible {
    margin-bottom: 1.625em;
    max-width: 100%;
    height: auto;
  }
  /* IE6 workaround - 2% space for possible borders */
  * html .flexible {
    width: 98%;
  }
  .bordered {
    margin-bottom: 1.625em;
    border: 2px #eee solid;
    border: 2px white solid;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  }
  /**
	* ----------------------------------------------------------------------- #
	*
	* Generic Content Classes
	*
	* Standardklassen zur Positionierung und Hervorhebung
	*
	* @section content-generic-classes
	*/
  .small {
    font-size: 0.85em;
  }
  .big {
    font-size: 1.2em;
  }
  .float-left {
    float: left;
    display: inline;
    margin: 0 1em 1.625em 0;
  }
  .float-right {
    float: right;
    display: inline;
    margin: 0 0 1.625em 1em;
  }
  .center {
    display: block;
    text-align: center;
    margin: 0 auto 1.625em auto;
  }
  .autor {
    text-align: center;
    font-size: 0.9em;
  }
  .zitat-page {
    margin: 4em 3em 1em 3em;
  }
  .logo-featured {
    margin-top: 65px;
  }
  /**
	* ------------------------------------------------------------------------------------------------- #
	*
	* Tabellen
	*
	* Generische Klassen für die Tabellenbreite und Gestaltungsvorschriften für Tabellen
	*
	* @section content-tables
	*/
  table {
    width: 100%;
    border-collapse: collapse;
    color: #4e4e4e;
    border-top: 1px #ccc solid;
    border-bottom: 1px #ccc solid;
    margin: 0 0 1.5em 0;
  }
  table.narrow {
    margin: 0 0 1.5625em 0;
  }
  table.narrow th,
  table.narrow td {
    padding: 0 0.5em;
    line-height: 1.5625;
  }
  table.fixed {
    table-layout: fixed;
  }
  table.bordertable {
    border: 1px #ccc solid;
  }
  table.bordertable thead th {
    background: #e0e0e0;
    border-right: 1px #ccc solid;
    border-bottom: 1px #ccc solid;
  }
  table.bordertable tbody th[scope="row"] {
    background: #f0f0f0;
  }
  table.bordertable tbody th {
    border-right: 1px solid #ccc;
  }
  table.bordertable tbody td {
    border-right: 1px solid #ccc;
  }
  caption {
    font-variant: small-caps;
  }
  th,
  td {
    line-height: 1.625em;
    vertical-align: top;
    padding: 0.78125em 0.5em;
  }
  th *:first-child,
  td *:first-child {
    margin-top: 0;
  }
  th.nowrap,
  td.nowrap {
    white-space: nowrap;
  }
  thead th {
    text-align: left;
    color: #000;
    border-bottom: 2px #000 solid;
  }
  tbody {
    /* highlight row on mouse over */
  }
  tbody th {
    text-align: left;
    border-top: 1px solid #ccc;
  }
  tbody td {
    text-align: left;
    border-top: 1px solid #ccc;
  }
  tbody tr:hover th,
  tbody tr:hover td {
    background: #f8f8f8;
  }
}

/* =Scss Variables
-------------------------------------------------------------- */
/* =Global
-------------------------------------------------------------- */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* =Template
-------------------------------------------------------------- */
.container {
  width: 80%;
  max-width: 1300px;
  height: 130px;
  margin: 0 auto;
  padding: 0;
  background-color: #fff;
}

.smaller .container {
  height: 80px;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
  transition: height 0.3s;
}

/* =Header
-------------------------------------------------------------- */
header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
  transition: height 0.3s;
}

header nav a {
  line-height: 150px;
  margin-left: 20px;
  color: #9fdbfc;
  font-weight: 700;
  font-size: 18px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

header nav a:hover {
  color: white;
}

header.smaller {
  height: 75px;
}

header.smaller nav a {
  line-height: 75px;
}

/* =Extras
-------------------------------------------------------------- */
.clearfix:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

/* =Media Queries
-------------------------------------------------------------- */
@media screen and (max-width: 1100px) {
  .container {
    width: 90%;
  }
}

@media all and (max-width: 660px) {
  /* =Header
	-------------------------------------------------------------- */
  .container {
    width: 100%;
  }
  header h1#logo {
    display: block;
    float: none;
    margin: 0 auto;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  header nav {
    display: block;
    float: right;
    width: 100%;
    height: 50px;
    margin: -36px 0 0 100px;
  }
  header nav a {
    line-height: 50px;
    margin: 0 10px;
  }
  header.smaller {
    height: 75px;
  }
  header.smaller h1#logo {
    height: 40px;
    line-height: 40px;
    font-size: 30px;
  }
  header.smaller nav {
    height: 35px;
  }
  header.smaller nav a {
    line-height: 35px;
  }
  header.smaller .container {
    height: 62px;
  }
}

@media all and (max-width: 600px) {
  #info-bar a {
    display: block;
  }
  #info-bar span.all-tutorials,
  #info-bar span.back-to-tutorial {
    width: 100%;
  }
  #info-bar span.all-tutorials,
  #info-bar span.back-to-tutorial {
    float: none;
    text-align: center;
  }
  #info-bar span.all-tutorials {
    border-bottom: solid 1px #0793e2;
  }
}

@media all {
  .intro {
    margin-bottom: 80px;
    margin-top: 35px;
    text-align: center;
  }
  .intro strong {
    letter-spacing: 1px;
  }
  .insertion {
    height: 320px;
    text-align: center;
    color: #fff;
  }
  .insertion.insertion__call2action {
    background: url("img/mandala.jpg") no-repeat center center;
    background-size: cover;
    padding: 50px;
  }
  .insertion.insertion__call2action .big {
    font-weight: 400;
  }
  .insertion h3 {
    color: #fff;
  }
  .insertion.insertion__tagcloud--osteopathie {
    background: url("img/tagcloud-osteopathie.jpg") no-repeat center center;
    height: 335px;
    background-size: cover;
  }
  .insertion.insertion__tagcloud--inpp {
    background: url("img/tagcloud-inpp.jpg") no-repeat center left;
    height: 335px;
    background-size: cover;
  }
  .insertion .wrap {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .grid__50-50--featured {
    text-align: center;
    margin-bottom: 50px;
  }
  .grid__50-50--featured .featured-image {
    width: 100%;
    margin-bottom: 45px;
  }
  .grid__50-50--featured h3 {
    font-size: 25px;
    font-weight: 300;
    color: #4e4e4e;
  }
  .readmore {
    font-size: 12px;
    text-transform: uppercase;
  }
  .readmore a {
    padding: 4px 9px 0 8px;
    display: inline-block;
    color: #aa85a3;
    text-decoration: none;
    border: 1px solid #aa85a3;
  }
  .readmore a:hover {
    background-color: #aa85a3;
    color: #fff;
    transition: 0.3s;
  }
}

@media screen {
  body {
    overflow-y: scroll;
    /* force vertical scrollbar */
    background-color: #f0ecf3;
  }
  /* Layout Module Configuration & fallback for unsupported media queries */
  .ym-wrapper {
    min-width: 760px;
    width: 80%;
    max-width: 1300px;
    margin: 0 auto;
  }
  .ym-wbox {
    background-color: #fff;
  }
  .title-img {
    height: 705px;
    margin-top: 130px;
  }
  .title-home {
    background: url("img/title-home.jpg") no-repeat top center;
    background-size: cover;
  }
  .title-osteopathie {
    background: url("img/osteopathie.jpg") no-repeat top center;
    background-size: cover;
  }
  .title-inpp {
    background: url("img/lampe.jpg") no-repeat top center;
    background-size: cover;
  }
  .title-about {
    background: url("img/mirjam-auerbach.jpg") no-repeat top center;
    background-size: cover;
  }
  .title-praxis {
    background: url("img/praxis.jpg") no-repeat top center;
    background-size: cover;
  }
  .title-kontakt {
    background: url("img/hello.jpg") no-repeat top center;
    background-size: cover;
  }
  .title-impressum {
    background: url("img/hand-fuss.jpg") no-repeat top center;
    background-size: cover;
  }
  .branding {
    float: left;
    margin: 54px 20px 0 20px;
  }
  .branding a {
    display: block;
    padding: 0;
    color: #4e4e4e;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    font-size: 18px;
  }
  .branding a:hover {
    background: none;
  }
  header nav {
    float: right;
    margin-right: 20px;
    margin-top: 36px;
  }
  header.smaller .branding {
    margin-top: 30px;
  }
  header.smaller nav {
    margin-top: 14px;
  }
  .main {
    padding: 50px 150px;
  }
  footer {
    margin: 0;
    padding: 1.5em;
    background-color: #e2dfdb;
  }
  footer p {
    margin: 0;
    font-size: 0.9em;
  }
  footer .left {
    float: left;
    margin-right: 20px;
  }
  footer .right {
    float: right;
    margin-left: 20px;
  }
  /* Column-Set Configuration: 1-3 (sidebar right) */
  .ym-column {
    display: block;
    overflow: hidden;
    padding-right: 340px;
    width: auto;
  }
  /* content - column */
  .ym-col1 {
    float: left;
    width: 100%;
  }
  /* sidebar - column */
  .ym-col3 {
    position: relative;
    float: left;
    width: 340px;
    margin-left: 0;
    margin-right: -340px;
  }
  .ym-col1 .ym-cbox {
    padding: 0 0.75em 0 1.5em;
  }
  .ym-col3 .ym-cbox {
    padding: 0 20px 0 12px;
  }
  .ym-gbox {
    padding: 0 1.5em 0 0;
  }
}

.ym-g50.ym-gl .ym-gbox {
  padding-right: 2em;
  padding-left: 0;
}

.ym-g50.ym-gr .ym-gbox {
  padding-left: 2em;
  padding-right: 0;
}

/* reset fallback values in modern browsers */
@media screen and (min-width: 0px) {
  .ym-wrapper {
    min-width: 0px;
  }
}

@media screen and (max-width: 1300px) {
  .title-img {
    height: 500px;
  }
}

@media screen and (max-width: 1100px) {
  .ym-wrapper {
    width: 90%;
  }
  .main {
    padding: 50px 100px;
  }
  .ym-g50.ym-gl .ym-gbox {
    padding-right: 1.5em;
  }
  .ym-g50.ym-gr .ym-gbox {
    padding-left: 1.5em;
  }
}

@media screen and (max-width: 1020px) {
  header .branding a  {
    padding: 0;
  }
  header nav {
    float: right;
    margin-top: -37px;
    margin-right: 0;
    width: 100%;
    margin-left: 100px;
    text-align: right;
  }
  header.smaller .branding {
    margin-top: 30px;
  }
  header.smaller nav {
    margin-top: -39px;
  }
  .title-img {
    height: 400px;
  }
}

@media screen and (max-width: 768px) {
  /* Linearisation for Grid- and Column-Module, based on CSS class .linearize-level-1 */
  .linearize-level-1,
  .linearize-level-1 > [class*="ym-c"],
  .linearize-level-1 > [class*="ym-g"] {
    /* linearization for grids and columns module */
    display: block;
    float: none;
    padding: 0;
    margin: 0;
    width: auto !important;
  }
  .linearize-level-1 > [class*="ym-c"] > [class*="ym-cbox"],
  .linearize-level-1 > [class*="ym-g"] > [class*="ym-gbox"] {
    /* reset defined gutter values */
    margin: 0;
    padding: 0;
    /* optional for containing floats */
    overflow: hidden;
  }
  .main {
    padding: 50px 50px;
  }
  header {
    text-align: center;
  }
  .logo {
    float: none;
    margin: 0 auto;
  }
  nav {
    float: none;
    margin: 30px auto 0px auto;
  }
}

@media screen and (max-width: 660px) {
  .ym-wrapper {
    width: 100%;
  }
  header.smaller .branding {
    margin-top: 18px;
  }
  header.smaller nav {
    margin-top: -39px;
  }
  .title-img {
    height: 300px;
  }
}

@media screen and (max-width: 480px) {
  .ym-wbox {
    padding: 0;
  }
  footer,
  .main {
    padding-left: 10px;
    padding-right: 10px;
  }
  /* Linearisation for Grid- and Column-Module, based on CSS class .linearize-level-2 */
  .linearize-level-2,
  .linearize-level-2 > [class*="ym-c"],
  .linearize-level-2 > [class*="ym-g"] {
    /* linearization for grids and columns module */
    display: block;
    float: none;
    padding: 0;
    margin: 0;
    width: auto !important;
  }
  .linearize-level-2 > [class*="ym-c"] > [class*="ym-cbox"],
  .linearize-level-2 > [class*="ym-g"] > [class*="ym-gbox"] {
    /* reset defined gutter values */
    margin: 0;
    padding: 0;
    /* optional for containing floats */
    overflow: hidden;
  }
  .title-img {
    height: 250px;
  }
}
