@import "../yaml-var-typography",
		"../mixins/yaml-mixins-core";

@media all {
	
	
// Home: Intro 
	.intro {
		margin-bottom: 80px;
		margin-top: 35px;
		text-align: center;

		strong {
			letter-spacing: 1px;
		}
	}
	
// Home: Call2Action
	.insertion {
		height: 320px;
		text-align: center;
		color:#fff;
		
		&.insertion__call2action {
			background: url("img/mandala.jpg") no-repeat center center;
			background-size: cover;
			padding:50px;

			.big {
				font-weight: 400;
			}
		}
		
		h3 { 
			color:#fff;
		}
		
		&.insertion__tagcloud--osteopathie {
			background: url("img/tagcloud-osteopathie.jpg") no-repeat center center;
			height:335px;
			background-size: cover;
		}

		&.insertion__tagcloud--inpp {
			background: url("img/tagcloud-inpp.jpg") no-repeat center left;
			height:335px;
			background-size: cover;
		}

		.wrap {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	
  


// Home: Featured Content
	
	.grid__50-50--featured {
		text-align: center;
		margin-bottom: 50px;
		
		.featured-image {
			width: 100%;
			margin-bottom: 45px;
		}
		
		h3 {
			font-size: 25px;
			font-weight: 300;
			color: #4e4e4e;
			
		}

		.ym-g50.ym-gr .featured-image {
			@include media( max-width 768px ) {
				margin-top: 40px;
			}
		}
	}
	
	
// Read more Link

	.readmore {
		
		font-size: 12px;
		text-transform: uppercase;
		
		a {
			padding: 4px 9px 0 8px;
			display: inline-block;
			color: $ym-link-text-color;
			text-decoration: none;
			border: 1px solid #aa85a3;
			
			&:hover {
				background-color: #aa85a3;
				color:#fff;
				transition: 0.3s; 
			}
		}
		
	}



}