@if $ym-base-floathandling == true {
	/**
	* @section Float Handling Module
	*/

	/* (en) clearfix method for clearing floats */
	/* (de) Clearfix-Methode zum Clearen der Float-Umgebungen */
	.ym-clearfix:before {
		content:"";
		display:table;
	}
	.ym-clearfix:after {
		clear:both;
		content:".";
		display:block;
		font-size:0;
		height:0;
		visibility:hidden;
	}

	/* (en) alternative solutions to contain floats */
	/* (de) Alternative Methoden zum Einschließen von Float-Umgebungen */
	.ym-contain-dt {
		@include ym-contain(dt);
	}
	.ym-contain-oh {
		@include ym-contain(oh);
	}
	.ym-contain-fl {
		@include ym-contain(fl);
	}
}
